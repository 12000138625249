import React, { FC } from "react";
import { Link } from "gatsby";
import SvgRoyal from "@icon/icofont/icons/royal.svg";
import SvgOpposite from "@icon/icofont/icons/opposite.svg";
import SvgFootPrint from "@icon/icofont/icons/foot-print.svg";
import SvgHeart from "@icon/icofont/icons/heart.svg";
import ScrollAnimation from "./ScrollAnimation";

type PropsType = {
    active: number;
}

const ValuesLinks: FC<PropsType> = (props: PropsType) => (
    <ScrollAnimation animateIn="fadeInUp">
        <div className="single-blog-status-bar">
            <span>Valorile noastre:</span>
            <span className={props.active === 1 ? "active" : ""}>
                <SvgRoyal />
                <Link to="/adorare" title="Adorare">Adorare</Link>
            </span>
            <span className={props.active === 2 ? "active" : ""}>
                <SvgOpposite />
                <Link to="/rascumparare" title="Răscumpărare">Răscumpărare</Link>
            </span>
            <span className={props.active === 3 ? "active" : ""}>
                <SvgFootPrint />
                <Link to="/krestere" title="Kreștere">Kreștere</Link>
            </span>
            <span className={props.active === 4 ? "active" : ""}>
                <SvgHeart />
                <Link to="/afectiune" title="Afecțiune">Afecțiune &amp; Ajutorare</Link>
            </span>
        </div>
    </ScrollAnimation>
);

export default ValuesLinks;
