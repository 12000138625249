import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout/Layout";
import Breadcrumb from "../components/layout/Breadcrumb";
import ValuesLinks from "../components/ValuesLinks";
import ScrollAnimation from "../components/ScrollAnimation";
import { Helmet } from "react-helmet";

const title = "Kreștere";

const KresterePage: FC = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    headlines {
                        krestere
                    }
                }
            }
        }
    `);

    return (
        <Layout>
            <Helmet title={title} />
            <Breadcrumb title={title} />

            <section className="page-area section-default section-padding-100">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="image-bordered">
                                <StaticImage
                                    src="../images/krestere.jpg"
                                    alt={title}
                                />
                            </div>
                            <ScrollAnimation animateIn="fadeInUp">
                                <h3 className="headline">{data.site.siteMetadata.headlines.krestere}</h3>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="fadeInUp">
                                <blockquote className="blockquote">
                                    <p>&quot;...creșteți în harul și în cunoștința Domnului și Mântuitorului nostru Isus Hristos.&quot;</p>
                                    <footer>2 Petru 3:18</footer>
                                </blockquote>
                            </ScrollAnimation>

                            <div className="height-50"></div>

                            <ScrollAnimation animateIn="fadeInUp">
                                <p>În fiecare săptămână ne întâlnim în grupe mici, pentru a experimenta viața împreună. Scopul nostru este ca fiecare dintre noi să semene tot mai mult cu Isus Cristos în toate aspectele vieții: fizic, social, intelectual și nu în ultimul rând, spiritual.</p>
                                <p>Tot săptămânal studiem Sfânta Scriptură și interpretăm Cuvântul lui Dumnezeu în comunitate, dorind să descoperim ce vrea Dumnezeu să fim și să facem.</p>
                            </ScrollAnimation>

                            <div className="height-50"></div>

                            <ValuesLinks active={3} />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default KresterePage;
