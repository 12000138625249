import React, { FC } from "react";
import { Link } from "gatsby";

type PropsType = {
    title: string;
    children?: unknown;
}

const Breadcrumb: FC<PropsType> = (props: PropsType) => (
    <section className="breadcrumb-area">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="breadcrumb-section">
                        <h1 className="page-title">{props.title}</h1>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Acasă</Link></li>
                            {props.children && (props.children)}
                            {!props.children && (<li className="breadcrumb-item active">{props.title}</li>)}
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default Breadcrumb;
